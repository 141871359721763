import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import '../styles/NextEvent.css';
import { events } from '../Components/eventsData'; // Importer les données des événements
import calendar from '../images/imagebank/event.jpg';

const NextEvent = () => {
  if (!events || events.length === 0) {
    return <div className="next-event-container">Aucun événement à venir.</div>;
  }

  const getNextEvent = () => {
    const now = new Date();
    const futureEvents = events
      .map(event => ({ ...event, date: new Date(`${event.date}T${event.time || '00:00:00'}`) }))
      .filter(event => event.date > now);

    futureEvents.sort((a, b) => a.date - b.date);

    return futureEvents[0];
  };

  const nextEvent = getNextEvent();

  if (!nextEvent) return null;

  const formatDate = (dateString, timeString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return timeString ? date.toLocaleDateString(undefined, options) : date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  };

  return (
    <div className="next-event">
      <Container>
        <Row className="align-items-center">
          <Col md={5}>
            <div className="image-container">
              <img src={calendar} alt="Calendrier" className="img-fluid rounded shadow" />
            </div>
          </Col>
          <Col md={7}>
            <h2 className="next-event-title">Prochain Évènement</h2>
            <h6>{formatDate(nextEvent.date, nextEvent.time)}</h6>
            <p>{nextEvent.description}</p>
            <p>
              Le TC Neupré organise fréquemment des événements pour ses membres et visiteurs.
              Rejoignez-nous pour profiter de nos activités variées et conviviales.
            </p>
            <Button href="/events" variant="primary">Voir tous les évènements</Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NextEvent;
