import React from 'react';
import { Carousel, Button, Container } from 'react-bootstrap';
import carousel1 from '../images/imagebank/tennis2.jpg';
import carousel2 from '../images/imagebank/padel12.jpg';
import carousel3 from '../images/imagebank/tennis9.jpg';
import carousel4 from '../images/imagebank/padel14.jpg';
import '../styles/Carousel.css';

const CarouselComponent = () => {
  return (
    <div className="carousel-container">
      <Container fluid>
        <div className="carousel-title">
          <h1>Tennis Padel Club Neupré</h1>
        </div>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carousel1}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carousel2}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carousel3}
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carousel4}
              alt="Fourth slide"
            />
          </Carousel.Item>
        </Carousel>
        <div className="fixed-buttons text-center">
          <Button variant="primary" href="https://ballejaune.com/club/Tc-Neupre" className="carousel-button">Réserver un terrain de tennis</Button>
          <Button variant="primary" href="https://www.sport-finder.com/fr/center/tc-neupre-padel-club" className="carousel-button">Réserver un terrain de padel</Button>
        </div>
      </Container>
    </div>
  );
};

export default CarouselComponent;
