import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles/wip.css';

import workInProgressImage from '../images/imagebank/wip.png';

const WorkInProgress = () => {
  return (
    <Container className="work-in-progress-page text-center">
      <img src={workInProgressImage} alt="Ouvriers au travail" className="work-in-progress-image"/>
      <h2>Cette page est en cours de construction. N'hésitez pas à revenir plus tard !</h2>
    </Container>
  );
};

export default WorkInProgress;
