import React, { useState } from 'react';
import { Container, Image, Button } from 'react-bootstrap';
import StagesTennisPadel from './StagesTennisPadel';
import StagesHappyKids from './StagesHappyKids';
import epsylon from '../images/epsylon.png';
import '../styles/Stages.css';

const Stages = () => {
  const [view, setView] = useState('tennis-padel');

  const renderContent = () => {
    if (view === 'happy-kids') {
      return <StagesHappyKids />;
    } else {
      return <StagesTennisPadel />;
    }
  };

  return (
    <Container className="stages-page">
      <div className="text-center mb-4">
        <Image src={epsylon} alt="Banner" className="img-fluid banner-image" />
      </div>
      <div className="text-center mb-4">
        <Button
          variant={view === 'tennis-padel' ? 'primary' : 'secondary'}
          className="mx-2"
          onClick={() => setView('tennis-padel')}
        >
          Stages Tennis & Padel - Pâques & Été 2024
        </Button>
        <Button
          variant={view === 'happy-kids' ? 'primary' : 'secondary'}
          className="mx-2"
          onClick={() => setView('happy-kids')}
        >
          Stages Happy Kids
        </Button>
      </div>
      {renderContent()}
    </Container>
  );
};

export default Stages;
