import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import '../styles/ContactForm.css';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vi8y8nl', 'template_ibr5ye5', e.target, 'zT0rWicnpr2HSBK2A')
      .then((result) => {
          console.log(result.text);
          alert('Message envoyé avec succès!');
      }, (error) => {
          console.log(error.text);
          alert('Échec de l\'envoi du message, veuillez réessayer plus tard.');
      });

    setFormData({
      name: '',
      email: '',
      message: ''
    });
  };

  return (
    <Container className="contact-page-container">
      <Row>
        <Col md={6}>
          <div className="contact-form-container">
            <h2>Contactez-nous</h2>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Nom</Form.Label>
                <Form.Control 
                  type="text" 
                  name="name" 
                  value={formData.name} 
                  onChange={handleChange} 
                  placeholder="Entrez votre nom" 
                  required 
                />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control 
                  type="email" 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                  placeholder="Entrez votre email" 
                  required 
                />
              </Form.Group>
              <Form.Group controlId="formMessage">
                <Form.Label>Message</Form.Label>
                <Form.Control 
                  as="textarea" 
                  name="message" 
                  value={formData.message} 
                  onChange={handleChange} 
                  rows={4} 
                  placeholder="Votre message" 
                  required 
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Envoyer
              </Button>
            </Form>
          </div>
        </Col>
        <Col md={6}>
          <div className="location-container">
            <Card className="location-card">
              <Card.Body>
                <p><i className="fas fa-map-marker-alt"></i> Rue des Chartreux 4, 4122 Neupré</p>
                <p><i className="fas fa-phone-alt"></i> Appelez-nous au 04 371 35 70</p>
                <p><i className="fas fa-envelope"></i> <a href="mailto:info@neupretennispadel.be">info@neupretennispadel.be</a></p>
                <div className="map-container">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2532.189706920187!2d5.512440515464378!3d50.54602959150742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c0e9d7a414e4a9%3A0xa181d2b807978885!2sTennis%20Club%20de%20Neupr%C3%A9%20%7C%20Tennis%20%26%20Padel!5e0!3m2!1sen!2sbe!4v1627562297934!5m2!1sen!2sbe"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
