import '../styles/Courts.css';

const Courts = () => {
  return (
    <div className="courts-info-section">
      <h2>Des courts adaptés à toute situation</h2>
      <div className="courts-info">
        <div className="court">
          <span className="number">17</span>
          <span className="label">Terrains</span>
        </div>
        <div className="court">
          <span className="number">7</span>
          <span className="label">Tennis Extérieurs</span>
        </div>
        <div className="court">
          <span className="number">6</span>
          <span className="label">Tennis Intérieurs</span>
        </div>
        <div className="court">
          <span className="number">2</span>
          <span className="label">Padel Extérieurs</span>
        </div>
        <div className="court">
          <span className="number">2</span>
          <span className="label"> Padel Intérieurs</span>
        </div>
      </div>
    </div>
  );
};
export default Courts;
