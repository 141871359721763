import React from 'react';
import { Table, Container, Button, Alert } from 'react-bootstrap';
import '../styles/AbonnementsPadel.css';

const AbonnementsPadel = () => {
  return (
    <Container>
      <h1 className="text-center my-4">Cotisations padel – 01/01/2024 – 31/12/2024</h1>
      <Table bordered hover className="cotisations-table">
        <thead>
          <tr>
            <th></th>
            <th>Habitants de Neupré</th>
            <th>Habitants hors Neupré</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Adultes et jeunes</td>
            <td>175€</td>
            <td>180€</td>
          </tr>
        </tbody>
      </Table>

      <div className="pass-visiteur text-center my-4">
        <h2>Pass visiteur</h2>
        <p>Pour les terrains extérieurs, une cotisation annuelle est possible.</p>
        <p>Il est également possible de prendre un pass visiteur pour 30€ via Balle Jaune.</p>
        <Button variant="primary" href="https://ballejaune.com/club/Tc-Neupre" target="_blank">
          Réserver via Balle Jaune
        </Button>
      </div>

      <div className="terrains-interieurs text-center my-4">
        <h2>Terrains intérieurs</h2>
        <p>Pour les terrains intérieurs, il faut passer par Sport Finder.</p>
        <p>Le tarif est de 36€ pour 1h30.</p>
        <Button variant="primary" href="https://www.sport-finder.com/fr/center/tc-neupre-padel-club" target="_blank">
          Réserver via Sport Finder
        </Button>
      </div>
    </Container>
  );
};

export default AbonnementsPadel;
