import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/TopBar.css';
import { FaMapMarkerAlt, FaPhoneAlt, FaFacebook } from 'react-icons/fa';

const TopBar = () => {
  return (
    <div className="top-bar d-none d-md-block">
      <Container>
        <Row>
          <Col md={6} className="text-md-left text-center">
            <p><FaMapMarkerAlt /><span className="icon-text">Rue des Chartreux, 4 - 4122 Plainevaux</span></p>
          </Col>
          <Col md={6} className="text-md-right text-center">
            <p>
              <FaPhoneAlt /><span className="icon-text">Appelez-nous au 04 371 35 70</span>
              <a href="https://www.facebook.com/TCNeupre" target="_blank" rel="noopener noreferrer">
                <FaFacebook /><span className="icon-text">Suivez-nous sur Facebook</span>
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TopBar;
