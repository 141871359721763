import React from 'react';
import { Carousel } from 'react-bootstrap';
import terrain1 from '../images/imagebank/DSC_2069_LuminarNeo-edit.jpg';
import terrain2 from '../images/terrain2.png';
import terrain3 from '../images/terrain3.png';
import terrain4 from '../images/terrain4.png';
import '../styles/ImageGallery.css';

const ImageGallery = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain1} alt="Terrain 1" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain2} alt="Terrain 2" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain3} alt="Terrain 3" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={terrain4} alt="Terrain 4" />
      </Carousel.Item>
    </Carousel>
  );
};

export default ImageGallery;
