import React, { useState } from 'react';
import { Button, Container, Alert } from 'react-bootstrap';
import AbonnementsEte from './AbonnementsEte';
import AbonnementsHiver from './AbonnementsHiver';
import ReservationsTennis from './ReservationsTennis';
import AbonnementsPadel from './AbonnementsPadel';
import '../styles/Tarifs.css';

const Tarifs = () => {
  const [selectedOption, setSelectedOption] = useState('combinaison');
  const [selectedSection, setSelectedSection] = useState('ete');
  const isAvailable = true; // Statut de disponibilité global

  return (
    <Container className="tarifs-page">
      <div className="text-center my-4">
        <Button variant={selectedOption === 'combinaison' ? 'primary' : 'secondary'} onClick={() => setSelectedOption('combinaison')}>
          Combinaison Tennis + Padel
        </Button>
        <Button variant={selectedOption === 'tennis' ? 'primary' : 'secondary'} onClick={() => setSelectedOption('tennis')}>
          Tennis
        </Button>
        <Button variant={selectedOption === 'padel' ? 'primary' : 'secondary'} onClick={() => setSelectedOption('padel')}>
          Padel
        </Button>
      </div>

      {selectedOption === 'combinaison' && (
        <>
          <div className="availability-banner text-center my-4">
            {isAvailable ? (
              <Alert variant="success">Statut de la disponibilité des abonnements: Disponible</Alert>
            ) : (
              <Alert variant="danger">Statut de la disponibilité des abonnements: Plus d'abonnement disponible</Alert>
            )}
          </div>
          <div className="combined-prices text-center my-4">
            <h3>Cotisation combinée adulte tennis + padel : tarif unique de 250€</h3>
            <h3>Cotisation tennis + padel jeune (2007-2012) : tarif unique de 200€</h3>
            <p className="priority-text">Jusqu’au 15/12/23 : priorité aux inscriptions combinées tennis et padel (paiement effectué)</p>
          </div>
          <div className="inscription-details">
            <h4>Comment vous inscrire ?</h4>
            <ul>
              <li>
                Renvoyer la fiche formulaire d’inscription saison 2024 complétée par mail à l’adresse suivante : 
                <a href="mailto:info@neupretennispadel.be"> info@neupretennispadel.be</a>
              </li>
              <li>
                Régler le montant de la cotisation par virement bancaire
                <ul>
                  <li>Numéro de compte : BE39 0011 3464 0019</li>
                  <li>Destinataire : TC NEUPRE</li>
                  <li>Communication libre : NOM + PRENOM (si plusieurs personnes, indiquez tous les membres qui en bénéficient)</li>
                </ul>
              </li>
              <li>
                Attention, TOUS les joueurs et joueuses doivent compléter le formulaire d’inscription ci-dessous, 
                en ce compris ceux qui ont déjà payé leur(s) cotisation(s), ce document est nécessaire pour vous activer dans Webclub
              </li>
            </ul>
          </div>
        </>
      )}

      {selectedOption === 'tennis' && (
        <>
          <div className="text-center my-4">
            <Button variant={selectedSection === 'ete' ? 'primary' : 'secondary'} onClick={() => setSelectedSection('ete')}>
              Abonnements été
            </Button>
            <Button variant={selectedSection === 'hiver' ? 'primary' : 'secondary'} onClick={() => setSelectedSection('hiver')}>
              Abonnements hiver
            </Button>
            <Button variant={selectedSection === 'carte' ? 'primary' : 'secondary'} onClick={() => setSelectedSection('carte')}>
              Réservations à la carte
            </Button>
          </div>

          {selectedSection === 'ete' && <AbonnementsEte />}
          {selectedSection === 'hiver' && <AbonnementsHiver />}
          {selectedSection === 'carte' && <ReservationsTennis />}
        </>
      )}

      {selectedOption === 'padel' && (
        <>
          <AbonnementsPadel />
        </>
      )}
    </Container>
  );
};

export default Tarifs;
