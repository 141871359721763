import React, { useState } from 'react';
import { Table, Row, Col, Card, Container, Button, Image } from 'react-bootstrap';
import { FaEnvelope } from 'react-icons/fa';
import '../styles/PadelStages.css';
import tenispadel from '../images/tenispadel.png';

const PadelStages = () => {
  const [activeTab, setActiveTab] = useState('adultes');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Container className="padel-stages-container">
      <Row className="justify-content-center mb-4">
        <Col md={10}>
          <Image src={tenispadel} alt="Banner" className="img-fluid banner-image" />
        </Col>
      </Row>

      <Row className="justify-content-center mb-4">
        <Col md={6} className="text-center">
          <Button variant={activeTab === 'adultes' ? 'success' : 'secondary'} onClick={() => handleTabClick('adultes')} className="mr-2">
            Stages Adultes
          </Button>
          <Button variant={activeTab === 'enfants' ? 'success' : 'secondary'} onClick={() => handleTabClick('enfants')}>
            Stages Enfants
          </Button>
        </Col>
      </Row>

      {activeTab === 'adultes' && (
        <>
          <h1 className="text-center my-4">Stage Adultes</h1>
          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <Card className="info-card text-center">
                <Card.Body>
                  <Card.Title>Du 19 au 22 août 2024</Card.Title>
                  <Card.Text>
                    <strong>2 Terrains - 2 Moniteurs</strong>
                  </Card.Text>
                  <Card.Text>
                    <strong>DAMES</strong> 100/200
                    <br />
                    Créneau : 18H00/19H30
                  </Card.Text>
                  <Card.Text>
                    <strong>HOMMES</strong> 100/200
                    <br />
                    Créneau : 19H30/21H00
                  </Card.Text>
                  <Card.Text>
                    Apéro offert le jeudi soir !
                  </Card.Text>
                  <Card.Text>
                    Infos et réservations : <FaEnvelope /> neupretennischallenge@gmail.com
                  </Card.Text>
                  <Card.Text>
                    <strong>10 is Padel</strong>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {activeTab === 'enfants' && (
        <>
          <h1 className="text-center my-4">Stage Enfants</h1>
          <Row className="justify-content-center mb-4">
            <Col md={8}>
              <Card className="info-card text-center">
                <Card.Body>
                  <Card.Title>Vacances d'Été</Card.Title>
                  <Card.Text>
                    <strong>Toutes les semaines !</strong>
                  </Card.Text>
                  <Card.Text>
                    <strong>Prix : 135 euros</strong>
                    <br />
                    De 6 à 16 ans
                  </Card.Text>
                  <Card.Text>
                    Attention ! Inscriptions limitées
                    <br />
                    Garderies, repas chauds possibles et matériel compris
                  </Card.Text>
                  <Card.Text>
                    Inscriptions : <FaEnvelope /> neupretennischallenge@gmail.com
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default PadelStages;
