import React from 'react';
import { Card } from 'react-bootstrap';
import '../styles/CommitteeMember.css'; 

const CommitteeMember = ({ member }) => {
  return (
    <Card className="committee-member">
      <Card.Img variant="top" src={member.photo} alt={member.name} />
      <Card.Body>
        <Card.Title>{member.name}</Card.Title>
        <Card.Text>{member.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default CommitteeMember;
