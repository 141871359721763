import React from 'react';
import { Table, Container, Alert } from 'react-bootstrap';
import '../styles/AbonnementsHiver.css';

const AbonnementsHiver = () => {
  const isAvailable = false;

  return (
    <Container>
      <div className="availability-banner text-center my-4">
        {isAvailable ? (
          <Alert variant="success">Statut de la disponibilité des abonnements: Disponible</Alert>
        ) : (
          <Alert variant="danger">Statut de la disponibilité des abonnements: Plus d'abonnement disponible</Alert>
        )}
      </div>
      <h2 className="text-center my-4">Abonnements - hiver 2024-2025</h2>
      <p className="text-center">Tennis Club Neupré</p>
      <Table bordered hover className="subscriptions-table">
        <thead>
          <tr>
            <th></th>
            <th>9h-13h</th>
            <th>13h-17h</th>
            <th>17h-18h</th>
            <th>18h-22h</th>
            <th>Après 22h</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Du Lundi au vendredi</td>
            <td>335€</td>
            <td>335€</td>
            <td>410€</td>
            <td>500€</td>
            <td>335€</td>
          </tr>
          <tr>
            <td>Week-end</td>
            <td>410€</td>
            <td>335€</td>
            <td>335€</td>
            <td>335€</td>
            <td>335€</td>
          </tr>
        </tbody>
      </Table>
      <div className="reservation-details my-4">
        <h4>Comment réserver ?</h4>
        <ul>
          <li>
            Pour réserver un terrain une fois par semaine sur une tranche horaire définie, veuillez contacter Martine Frère :
            <a href="mailto:martine.frere@neupretennispadel.be"> martine.frere@neupretennispadel.be</a>
          </li>
        </ul>
      </div>
    </Container>
  );
};

export default AbonnementsHiver;
