import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logoJA from '../images/logoJA.png'; // Assurez-vous que le chemin est correct
import logo from '../images/logo.png'; // Assurez-vous que le chemin est correct
import '../styles/Footer.css'; // Assurez-vous de créer ce fichier pour les styles personnalisés

const Footer = () => {
  return (
    <footer className="bg-primary text-white pt-4">
      <Container>
        <Row className="text-center">
          <Col md={4} className="mb-3 mb-md-0">
            <p><i className="fas fa-map-marker-alt"></i> Rue des Chartreux, 4 - 4122 Plainevaux</p>
          </Col>
          <Col md={4} className="mb-3 mb-md-0">
            <img
              src={logo}
              height="50"
              className="d-inline-block align-top"
              alt="Web JA Logo"
            />
          </Col>
          <Col md={4}>
            <p><i className="fas fa-phone-alt"></i> Appelez-nous au 04 371 35 70</p>
            <p><i className="fas fa-envelope"></i> <a href="mailto:info@neupretennispadel.be" className="text-white">info@neupretennispadel.be</a></p>
          </Col>
        </Row>
        <Row className="bg-dark text-center py-3 mt-3">
          <Col className="footer-bottom">
            <p className="mb-0">T.C. Neupré © 2024. Tout droit réservé.</p>
            <p className="mb-0">
              Création de sites Internet | Web JA <img src={logoJA} alt="Web JA Logo" className="footer-logo" />
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
