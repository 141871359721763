import React from 'react';
import { Table, Button, Row, Col, Card } from 'react-bootstrap';
import { FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { GiTennisRacket, GiTrophyCup, GiTennisCourt } from 'react-icons/gi';
import '../styles/Stages.css';

const StagesTennisPadel = () => {
  const stages = [
    { week: 28, dates: 'du 08 au 12 juillet', price: '135€' },
    { week: 29, dates: 'du 15 au 19 juillet', price: '135€' },
    { week: 30, dates: 'du 22 au 26 juillet', price: '135€' },
    { week: 31, dates: 'du 29 juillet au 02 août', price: '135€' },
    { week: 32, dates: 'du 05 au 09 août', price: '135€' },
    { week: 33, dates: 'du 12 au 16 août (15 août férié)', price: '110€' },
    { week: 34, dates: 'du 19 au 23 août', price: '135€' },
  ];

  const formulas = [
    {
      title: 'TENNIS PADEL',
      description: [
        'Stages de tennis intensifs & padel',
        'Tous niveaux dès 6 ans',
        'Activités complémentaires: mini-foot, baseball, ...'
      ],
      email: 'neupretennischallenge@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#28a745',
      icon: <GiTennisRacket />,
    },
    {
      title: 'PADEL TENNIS',
      description: [
        'Stages de padel intensifs & tennis',
        'Tous niveaux dès 6 ans',
        'Activités complémentaires: mini-foot, baseball, ...'
      ],
      email: 'neupretennischallenge@gmail.com',
      info: '10 is Padel BE58 3631 4827 8179',
      bgColor: '#28a745',
      icon: <GiTennisCourt />,
    },
    {
      title: 'TENNIS COMPETITION',
      description: [
        'Entraînement et suivi en compétition officielle dès 9 ans',
        'Supplément de 30 €/semaine'
      ],
      email: 'simonarnoldtennis@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#007bff', // Changed to green
      icon: <GiTrophyCup />,
    },
    {
      title: 'ADULTES',
      description: [
        'En soirée, à la carte exclusivement > groupes constitués par vos soins !'
      ],
      email: 'neupretennischallenge@gmail.com',
      info: 'Epsylon BE51 0018 6884 7062',
      bgColor: '#007bff',
      icon: <GiTennisRacket />,
    },
  ];

  return (
    <>
      <h1 className="text-center my-4">Stages Tennis & Padel - Pâques & Été 2024</h1>
      <h2 className="text-center my-4">4 formules disponibles</h2>
      <Row className="mb-4">
        {formulas.map((formula, index) => (
          <Col md={6} lg={6} key={index} className="mb-4">
            <Card className="formula-card" style={{ backgroundColor: formula.bgColor }}>
              <Card.Body>
                <div className="formula-icon">{formula.icon}</div>
                <Card.Title className="text-center formula-title">{formula.title}</Card.Title>
                <Card.Text>
                  <ul className="formula-description">
                    {formula.description.map((line, i) => <li key={i}>{line}</li>)}
                  </ul>
                </Card.Text>
                <Card.Text className="text-center"><strong>Renseignements & Inscriptions</strong></Card.Text>
                <Card.Text className="text-center"><FaEnvelope /> {formula.email}</Card.Text>
                <Card.Text className="text-center"><FaInfoCircle /> {formula.info}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Table responsive bordered hover className="stages-table">
        <thead>
          <tr>
            <th>Semaine</th>
            <th>Dates</th>
            <th>Prix</th>
          </tr>
        </thead>
        <tbody>
          {stages.map((stage, index) => (
            <tr key={index}>
              <td>Semaine {stage.week}</td>
              <td>{stage.dates}</td>
              <td>
                <Button variant="danger" className="price-button">{stage.price}</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="text-center my-4">
        <Button variant="danger" size="lg" className="mb-4">
          Nombre de places limité ! Souscrivez dès aujourd'hui...
        </Button>
      </div>
      <div className="information-section">
        <h3>Informations générales</h3>
        <p>Activités de <strong>09:00 à 15:30</strong></p>
        <p>Garderie possible dès <strong>8:20</strong> et jusqu'à <strong>16:30</strong></p>
        <p>Repas chauds de midi proposés <strong>25€</strong> semaines de 4 jours & <strong>30€/semaine</strong> de 5 jours à réserver à l'inscription</p>
        <p>Prêt de matériel</p>
        <p>Attestations mutuelles sur demande</p>
      </div>
      <div className="alert alert-danger text-center mt-4">
        Merci de respecter les modalités spécifiques à chaque formule proposée EN ANNEXE pour infos et paiements. Merci d'indiquer à chaque fois en communication : Nom et prénom du stagiaire, année de naissance, formule et semaine choisie.
      </div>
    </>
  );
};

export default StagesTennisPadel;
